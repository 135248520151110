import { useState, useEffect } from 'react';
import axios from '../../axiosInstance';

const useProcessData = (dailyReadingId) => {
  const [processData, setProcessData] = useState([]);
  const [totalPublications, setTotalPublications] = useState(0);
  const [changes, setChanges] = useState({});

  useEffect(() => {
    axios.get(`/dailyreadings/${dailyReadingId}`) // Adjust to fetch specific daily reading
      .then(response => {
        setProcessData(response.data.process_data);
        setTotalPublications(response.data.total_publications);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dailyReadingId]); // Depend on dailyReadingId to refetch when it changes

  const handleCheckboxChange = (type, id, isChecked) => {
    if (type === 'is_read' && isChecked) {
      // Find the process that matches the id
      const process = processData.find(p => p.id === id);

      console.log(process);
      if (process) {
        // Check if at least one publication is marked as original
        const hasOriginal = process.publications.some(pub => changes[pub.id]?.is_original || pub.is_original);
        if (!hasOriginal) {
          alert('Você precisa marcar pelo menos um como original!');
          return;
        }
      }
    }

    setProcessData(prevProcessData => 
        prevProcessData.map(process => {
          if (type === 'is_read' && process.id === id) {
            return { ...process, [type]: isChecked };
          }
    
          if (type === 'is_original') {
            const updatedPublications = process.publications.map(pub =>
              pub.id === id ? { ...pub, is_original: isChecked } : pub
            );
    
            const hasOriginal = updatedPublications.some(pub => pub.is_original);
            const updatedProcess = { ...process, publications: updatedPublications };
    
            if (!hasOriginal) {
              updatedProcess.is_read = false;
            }
    
            return updatedProcess;
          }
    
          return process;
        })
      );
  
    setChanges(prevChanges => ({
      ...prevChanges,
      [id]: { ...prevChanges[id], [type]: isChecked }
    }));
  };
  

  const handleSaveChanges = (dailyReadingId) => {
    return axios.post(`/dailyreadings/save_changes/${dailyReadingId}`, changes)
      .then(response => {
        console.log(response.data.status);
        return response.data.status;
      })
      .catch(() => {
        alert('Ocorreu um erro ao salvar.');
      });
  };

  return {
    processData,
    totalPublications,
    handleCheckboxChange,
    handleSaveChanges
  };
};

export default useProcessData;
