// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DailyReadings from './components/dailyReading/DailyReadings';
import DuplicatesPage from './components/dailyReading/DuplicatesPage';
import ReadingPage from './components/dailyReading/ReadingPage';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import ProtectedRoute from './components/auth/ProtectedRoute';
import MuiToolbar from './components/common/MuiToolbar';
import MuiDrawer from './components/common/MuiDrawer';
import { CssBaseline, Box } from '@mui/material';
import ChangePassword from './components/auth/ChangePassword';
import ListDailyReadings from './components/dailyReading/ListDailyReadings';
import { REACT_ROUTES } from './routes';
import UserList from './components/user/UserList';
import UserForm from './components/user/UserForm';
import StepFour from './components/dailyReading/StepFour';


const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Router>
        <MuiToolbar toggleDrawer={toggleDrawer} />
        {token && <MuiDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />}
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
          <Routes>
          <Route path={REACT_ROUTES.ROOT} element={<Login />} />
            <Route path={REACT_ROUTES.LOGIN} element={<Login />} />
            <Route path={REACT_ROUTES.SIGNUP} element={<Signup />} />
            <Route path={REACT_ROUTES.NEW_DAILY_READING} element={<ProtectedRoute component={DailyReadings} />} />
            <Route path={REACT_ROUTES.DAILY_READINGS} element={<ProtectedRoute component={ListDailyReadings} />} />
            <Route path={REACT_ROUTES.DUPLICATES} element={<ProtectedRoute component={DuplicatesPage} />} />
            <Route path={REACT_ROUTES.PUBLICATION_READING} element={<ProtectedRoute component={ReadingPage} />} />
            <Route path={REACT_ROUTES.PUBLICATION_STEP_FOUR} element={<ProtectedRoute component={StepFour} />} />


            <Route path={REACT_ROUTES.CHANGE_PASSWORD} element={<ProtectedRoute component={ChangePassword} />} />

            <Route path={REACT_ROUTES.USERS} element={<ProtectedRoute component={UserList} />} />
            <Route path={REACT_ROUTES.USERS_NEW} element={<ProtectedRoute component={UserForm} />} />
            <Route path={REACT_ROUTES.USERS_EDIT} element={<ProtectedRoute component={UserForm} />} />
            
          </Routes>
        </Box>
      </Router>
    </Box>
  );
};

export default App;
