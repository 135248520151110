// src/components/dailyReading/ListDailyReadings.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from '../../axiosInstance';
import {
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TableSortLabel,
  TablePagination,
  TextField,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const ListDailyReadings = () => {
  const [dailyReadings, setDailyReadings] = useState([]);
  const [filteredReadings, setFilteredReadings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDailyReadings = async () => {
      try {
        const response = await instance.get('/dailyreadings/list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setDailyReadings(response.data.daily_readings);
        setFilteredReadings(response.data.daily_readings);
        setTotalCount(response.data.total_count);
        setLoading(false);
      } catch (error) {
        console.error('Falha ao buscar leituras diárias', error);
        setLoading(false);
      }
    };

    fetchDailyReadings();
  }, []);

  useEffect(() => {
    const filtered = dailyReadings.filter((reading) =>
      (reading.title ? reading.title.toLowerCase() : '').includes(search.toLowerCase())
    );
    
    setFilteredReadings(filtered);
    setPage(0); // Reset to first page on filter change
  }, [search, dailyReadings]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedReadings = filteredReadings.sort((a, b) => {
    if (orderBy === 'date') {
      return order === 'asc'
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else if (orderBy === 'id' || orderBy === 'total_processes' || orderBy === 'total_publications') {
      return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  const paginatedReadings = sortedReadings.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Leituras Diárias (Total: {totalCount})
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
      <Button 
            variant="contained" 
            color="primary" 
            sx={{ mr: 2 }}
            onClick={() => navigate('/dailyreadings/new')}
        >
            NOVA LEITURA
        </Button>
        <TextField
          variant="outlined"
          placeholder="Pesquisar por título"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ mr: 2 }}
        />
        <IconButton>
          <SearchIcon />
        </IconButton>
        
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={() => handleRequestSort('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'date' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={orderBy === 'date' ? order : 'asc'}
                  onClick={() => handleRequestSort('date')}
                >
                  Data
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'title' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={orderBy === 'title' ? order : 'asc'}
                  onClick={() => handleRequestSort('title')}
                >
                  Título
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                Total de Processos
              </TableCell>
              <TableCell align="right">
                Total de Publicações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedReadings.map((reading) => (
              <TableRow 
                key={reading.id}
                onClick={() => navigate(`/duplicates/${reading.id}`)}
                sx={{ cursor: 'pointer' }}
                >
                <TableCell>{reading.id}</TableCell>
                <TableCell>{format(parseISO(reading.date), 'dd/MM/yyyy', { locale: ptBR })}</TableCell>
                <TableCell>{reading.title}</TableCell>
                <TableCell align="right">{reading.total_processes}</TableCell>
                <TableCell align="right">{reading.total_publications}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredReadings.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Box>
  );
};

export default ListDailyReadings;
