import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import axios from '../axiosInstance';
import DiffMatchPatch from 'diff-match-patch';
import '../common/styles.css';
import useProcessData from './useProcessData';
import ProcessList from './ProcessList';
import { Button, Box, Typography, Alert } from '@mui/material';
import { REACT_ROUTES } from '../../routes';

const DuplicatesPage = () => {
  const { dailyReadingId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const {
    processData,
    totalPublications,
    handleCheckboxChange,
    handleSaveChanges
  } = useProcessData(dailyReadingId);

  const [saveSuccessful, setSaveSuccessful] = useState(false);

  useEffect(() => {
    const handleAccordionClick = (event) => {
      event.target.classList.toggle("active");
      const panel = event.target.nextElementSibling;
      if (panel) {
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      }
    };

    const handleSubAccordionClick = (event) => {
      const subPanel = event.target.nextElementSibling;
      subPanel.classList.toggle("show");
    };

    const accordions = document.getElementsByClassName("accordion");
    for (let i = 0; i < accordions.length; i++) {
      accordions[i].addEventListener("click", handleAccordionClick);
    }

    const subAccordions = document.getElementsByClassName("subaccordion");
    for (let i = 0; i < subAccordions.length; i++) {
      subAccordions[i].addEventListener("click", handleSubAccordionClick);
    }

    return () => {
      for (let i = 0; i < accordions.length; i++) {
        accordions[i].removeEventListener("click", handleAccordionClick);
      }
      for (let i = 0; i < subAccordions.length; i++) {
        subAccordions[i].removeEventListener("click", handleSubAccordionClick);
      }
    };
  }, [processData]);

  function splitAndExtractLines(text) {
    const lines = text.split('<br>');
  
    // Find the index of the line starting with "Intimacao" or "Intimado"
    let startLineIndex = null;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith("Intimacao") || lines[i].startsWith("Intimado")) {
        startLineIndex = i;
        break; // Stop searching once found
      }
    }
  
    let remainingLines;
  
    if (startLineIndex !== null) {
      remainingLines = lines.slice(startLineIndex);
    } else {
      remainingLines = lines;
    }
  
    return remainingLines.join('\n');
  }

  const compareTexts = (text1, text2) => {
    const title = `Comparando publicações ${text1} e ${text2}`;
    const text1Content = document.getElementById(text1).querySelector("#content").innerHTML;
    const text2Content = document.getElementById(text2).querySelector("#content").innerHTML;

    const body1Content = splitAndExtractLines(text1Content);
    console.log(body1Content);
    const body2Content = splitAndExtractLines(text2Content);
    console.log(body2Content);

    const dmp = new DiffMatchPatch();
    const diff = dmp.diff_main(body1Content, body2Content);
    dmp.diff_cleanupSemantic(diff);
    const diffHtml = dmp.diff_prettyHtml(diff);

    const newWindow = window.open('', '_blank', 'width=800,height=600');
    newWindow.document.write(`<html><head><title>${title}</title></head><body>`);
    newWindow.document.write(`<div>${diffHtml}</div>`);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
  };

  const validateAllReadCheckboxes = () => {
    return processData.every(process => process.is_read);
  };

  const handleSaveChangesWithValidation = async () => {
    if (!validateAllReadCheckboxes()) {
      setError('Todos os processos devem ser marcados como lidos antes de salvar.');
      return false;
    }
    try {
      const statusResponse = await handleSaveChanges(dailyReadingId);
      console.log(statusResponse);
      if (statusResponse !== 'success') {
        setError('Erro ao salvar alterações');
        return false;
      } else {
        setSuccess('Rascunho salvo com sucesso.')
        setSaveSuccessful(true);
      }
      return true;
    } catch (error) {
      setError('Erro ao salvar alterações:', error);
      setSaveSuccessful(false);
      return false;
    }
  };

  const handleNext = async () => {
    if (!saveSuccessful) {
      const saveResult = await handleSaveChangesWithValidation();
      if (!saveResult) {
        return;
      }
    }
    //console.log('Navigating with dailyReadingId:', dailyReadingId);  // Debugging output
    navigate(REACT_ROUTES.PUBLICATION_READING.replace(':dailyReadingId', dailyReadingId));
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Publicações (Total: {totalPublications})
      </Typography>
      {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}
      {success && <Alert severity="success" onClose={() => setSuccess(null)}>{success}</Alert>}

      <ProcessList
        processData={processData}
        handleCheckboxChange={handleCheckboxChange}
        compareTexts={compareTexts}
      />
      <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
        <Button variant="outlined" color="primary" onClick={handleSaveChangesWithValidation}>
          SALVAR RASCUNHO
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          PRÓXIMO
        </Button>
      </Box>
    </Box>
  );
};

export default DuplicatesPage;
