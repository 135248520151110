import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BookIcon from '@mui/icons-material/Book';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Person2 } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MuiDrawer = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();
  //const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');
  
  const handleNavigation = (path) => {
    navigate(path);
    toggleDrawer(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
    toggleDrawer(false);
  };

  const items = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Leitura de Publicações', icon: <BookIcon />, path: '/dailyreadings' },
    { text: 'Alterar Senha', icon: <LockIcon />, path: '/change-password' },
    { text: 'Usuários', icon: <Person2 />, path: '/users' },
  ];

  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
      <Box
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        sx={{ width: 250 }}
      >
        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center', backgroundColor: 'black' }}>
          <img src="/client_logo.png" alt="Client Logo" style={{ width: '80%', height: 'auto' }} />
        </Box>
        <Divider />
        <List>
          {items.map(({ text, icon, path }, index) => (
            <ListItem component="div" onClick={() => handleNavigation(path)} key={text} style={{ cursor: 'pointer' }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <List>
          <ListItem component="div" onClick={handleLogout} style={{ cursor: 'pointer' }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                >
                  {username}
                </Typography>
              }
              secondary="Sair"
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default MuiDrawer;
