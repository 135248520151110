import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ onFileDrop }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      onFileDrop(reader.result);
    };
    reader.readAsText(acceptedFiles[0]);
  }, [onFileDrop]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={{ border: '2px dashed #0087F7', padding: '20px', textAlign: 'center' }}>
      <input {...getInputProps()} />
      <p>Arraste e solte um arquivo aqui ou clique para selecionar um arquivo</p>
    </div>
  );
};

export default Dropzone;
