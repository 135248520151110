// src/components/UserList.js
import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Delete as DeleteIcon, Edit as EditIcon, Person as PersonIcon } from '@mui/icons-material';
import instance from '../../axiosInstance';
import { REACT_ROUTES } from '../../routes';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await instance.get('/users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setUsers(response.data.users);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError(error.response.data.message);
        navigate(REACT_ROUTES.DAILY_READINGS);
      } else {
        console.error('Error fetching users', error);
      }
    }
  }, [navigate]);

  
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  

  const deleteUser = async (id) => {
    try {
      await instance.delete(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user', error);
    }
  };

  return (
    <TableContainer component={Paper}>
        {error && <div className="error">{error}</div>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Profile Picture</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>
                <PersonIcon /> {user.username}
              </TableCell>
              <TableCell>
                {user.profile_picture && (
                  <img src={user.profile_picture} alt="Profile" width="50" height="50" />
                )}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => navigate(`/users/${user.id}`)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => deleteUser(user.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="contained" color="primary" onClick={() => navigate('/users/new')}>
        Add User
      </Button>
    </TableContainer>
  );
};

export default UserList;
