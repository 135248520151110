import React from 'react';

const PublicationItem = ({ publication, handleCheckboxChange }) => {
  if (!publication) {
    return <div>Publication data is missing.</div>;
  }

  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <button className="subaccordion">Publicação: {publication.publicacao}</button>
      <div className="subpanel" id={publication.publicacao}>
        Original:
        <input
          type="checkbox"
          className="original-checkbox"
          checked={publication.is_original}
          onChange={(e) => handleCheckboxChange('is_original', publication.id, e.target.checked)}
        />
        <br />
        Publicação: {publication.publicacao}
        <br />
        Data de Disponibilização: {publication.data_disponibilizacao}
        <br />
        Data de Publicação: {publication.data_publicacao}
        <br />
        Jornal: {publication.jornal}
        <br />
        Local: {publication.local}
        <br />
        Página: <div id="content">{formatTextWithLineBreaks(publication.pagina)}</div>
        <br />
      </div>
    </>
  );
};

export default PublicationItem;
