// src/components/UserForm.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button } from '@mui/material';
import instance from '../../axiosInstance';
import { REACT_ROUTES } from '../../routes';

const UserForm = () => {
  const { id } = useParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
        try {
          const response = await instance.get(`/users/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          const user = response.data;
          setUsername(user.username);
          setProfilePicture(user.profile_picture || '');
        } catch (error) {
          console.error('Error fetching user', error);
        }
      };
      
    if (id) {
      fetchUser();
    }
  }, [id]);

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = { username, password, profile_picture: profilePicture };

    try {
      if (id) {
        await instance.put(`/users/${id}`, userData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
      } else {
        await instance.post('/users', userData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
      }
      navigate(REACT_ROUTES.USERS);
    } catch (error) {
      console.error('Error saving user', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Profile Picture URL"
        variant="outlined"
        fullWidth
        value={profilePicture}
        onChange={(e) => setProfilePicture(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" type="submit">
        {id ? 'Update' : 'Create'} User
      </Button>
    </Box>
  );
};

export default UserForm;
