import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

const MuiToolbar = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const userPhotoUrl = localStorage.getItem('userPhotoUrl');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userPhotoUrl');
    navigate('/login');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          UAPÉ - WebJUS
        </Typography>
        {token ? (
            <>
                <Avatar src={userPhotoUrl} alt="User Photo" sx={{ marginRight: 2 }} />
                <Divider />
                <LockIcon />
                <Button onClick={handleLogout} color="inherit">Logout</Button>
            </>
        ) : (
          <Button onClick={handleLogin} color="inherit">Login</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MuiToolbar;
