import React, { useEffect, useState } from 'react';
import instance from '../../axiosInstance';
import { useParams } from 'react-router-dom';
import '../common/styles.css';

const StepFour = () => {
    const { dailyReadingId } = useParams();
    const [processData, setProcessData] = useState([]);
    const [formattedData, setFormattedData] = useState('');

    useEffect(() => {
        instance.get(`/dailyreadings/analysis/${dailyReadingId}`)
            .then(response => {
                setProcessData(response.data.process_data);
            })
            .catch(error => {
                console.error('Erro ao buscar dados:', error); // PT-BR error message
            });
    }, [dailyReadingId]);

    useEffect(() => {
        if (processData.length > 0) {
            const formattedText = processData.map(process => {
                const publications = process.publications.map(pub => (
                    `<p><b>Publicação:</b> ${pub.publicacao}<br/>
                    <b>Data de Disponibilização:</b> ${pub.data_disponibilizacao}<br/>
                    <b>Data de Publicação:</b> ${pub.data_publicacao}<br/>
                    <b>Jornal:</b> ${pub.jornal}<br/>
                    <b>Local:</b> ${pub.local}<br/>
                    <b>Página:</b> ${pub.pagina}<br/>
                    <b>Ação:</b> ${pub.action ? pub.action.description : 'N/A'}<br/>
                    ${pub.custom_action ? `<b>Ação Personalizada:</b> ${pub.custom_action}<br/>` : ''}
                    </p>`
                )).join('');

                const nonOriginals = process.non_original_publications.join(', ');

                return `<h2>Número do Processo: ${process.process_number}</h2>
                <h3>Publicações:</h3>
                ${publications}
                <h4>Publicações Duplicadas para esse processo:</h4>
                ${nonOriginals}

                `;
            }).join('');

            setFormattedData(formattedText);
        }
    }, [processData]);

    return (
        <div>
            <h1>Resumo das Ações</h1> 
            <div 
                dangerouslySetInnerHTML={{__html: formattedData}} 
                style={{ width: '100%', padding: '10px', fontSize: '14px', lineHeight: '1.6' }}
            />
        </div>
    );
};

export default StepFour;
