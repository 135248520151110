// src/routes.js
export const REACT_ROUTES = {
    ROOT: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    NEW_DAILY_READING: '/dailyreadings/new',
    DAILY_READINGS: '/dailyreadings',
    DUPLICATES: `/duplicates/:dailyReadingId`,
    PUBLICATION_READING: `/publication/reading/:dailyReadingId`,
    PUBLICATION_STEP_FOUR: `/publication/stepfour/:dailyReadingId`,
    CHANGE_PASSWORD: '/change-password',

    USERS: '/users',
    USERS_NEW: '/users/new',
    USERS_EDIT: `/users/:id`,
    
  };
  