import React, { useEffect, useState } from 'react';
import instance from '../../axiosInstance';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../common/styles.css';
import { useParams, useNavigate } from 'react-router-dom';
import { MenuItem, Select, TextField, Button, Box, InputLabel } from '@mui/material';
import { REACT_ROUTES } from '../../routes';

const ReadingPage = () => {
  const { dailyReadingId } = useParams();
  const [processData, setProcessData] = useState([]);
  const [totalPublications, setTotalPublications] = useState(0);
  const [copiedText, setCopiedText] = useState('');
  const [selectedActions, setSelectedActions] = useState({});
  const [customActions, setCustomActions] = useState({});
  const navigate = useNavigate();

  const actions = [
    { value: '', label: 'Select an action' }, // Added this line to handle default value
    { value: '1', label: 'NADA A REALIZAR' },
    { value: '2', label: 'PRAZO' },
    { value: '3', label: 'AUDIÊNCIA' },
    { value: '0', label: 'Outros' },
  ];

  useEffect(() => {
    instance.get(`/dailyreadings/analysis/${dailyReadingId}`)
      .then(response => {
        setProcessData(response.data.process_data);
        setTotalPublications(response.data.total_publications);

        const initialActions = {};
          response.data.process_data.forEach(process => {
            process.publications.forEach(pub => {
              const actionKey = `${process.process_number}-${pub.id}`;
              initialActions[actionKey] = pub.action.id; // Assuming your backend response structure
            });
          });
          setSelectedActions(initialActions);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dailyReadingId]);

  useEffect(() => {
    const handleAccordionClick = (event) => {
      event.target.classList.toggle("active");
      const panel = event.target.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    };

    const accordions = document.getElementsByClassName("accordion");
    for (let i = 0; i < accordions.length; i++) {
      accordions[i].addEventListener("click", handleAccordionClick);
    }

    return () => {
      for (let i = 0; i < accordions.length; i++) {
        accordions[i].removeEventListener("click", handleAccordionClick);
      }
    };
  }, [processData]);

  const formatTextWithLineBreaks = (text) => {
    const paragraphs = text.split(/\n{2,}/); 
    return paragraphs.map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph}
        {index !== paragraphs.length - 1 && <br />} 
      </React.Fragment>
    ));
  };

  const handleActionChange = (processId, pubId, event) => {
    const { value } = event.target;
    setSelectedActions((prev) => ({
      ...prev,
      [`${processId}-${pubId}`]: value,
    }));
  };

  const handleCustomActionChange = (processId, pubId, event) => {
    const { value } = event.target;
    setCustomActions((prev) => ({
      ...prev,
      [`${processId}-${pubId}`]: value,
    }));
  };

  const handleSubmitAction = () => {
    const allActionsSelected = Object.keys(selectedActions).length === processData.flatMap(process => process.publications).length;
    
    if (!allActionsSelected) {
      alert("Please fill out all action selections before submitting.");
      return;
    }
  
    const submissionData = processData.flatMap((process) =>
      process.publications.map((pub) => {
        const actionKey = `${process.process_number}-${pub.id}`;
        const selectedAction = selectedActions[actionKey];
        const customAction = selectedAction === '0' ? customActions[actionKey] : null;
  
        return {
          process_number: process.process_number,
          publication_id: pub.id,
          action: selectedAction,  // Assuming the dropdown now sends action ID
          custom_action: customAction,
        };
      })
    );
  
    instance.post(`/dailyreadings/analysis/${dailyReadingId}/submit-actions`, { actions: submissionData })
      .then(response => {
        //console.log('Actions submitted successfully:', response.data);
        //alert("Actions submitted successfully!");
        navigate(REACT_ROUTES.PUBLICATION_STEP_FOUR.replace(':dailyReadingId', dailyReadingId));
      })
      .catch(error => {
        console.error('Error submitting actions:', error);
        alert("Error submitting actions, please try again.");
      });
  };
  

  return (
    <div>
      <h1>Publicações Originais (Total: {totalPublications})</h1>
      {processData.flatMap((process) =>
        process.publications.map((pub) => (
          <div className="process" key={`${process.process_number}-${pub.id}`}>
            <button className="accordion">
              {process.process_number} Publicação Original: {pub.publicacao}
            </button>
            <div className="panel">
              <div className="publication-details">
                <CopyToClipboard text={`Publicação: ${pub.publicacao}\nData de Disponibilização: ${pub.data_disponibilizacao}\nData de Publicação: ${pub.data_publicacao}\nJornal: ${pub.jornal}\nLocal: ${pub.local}\nPágina: ${pub.pagina}`}
                  onCopy={() => setCopiedText(pub.publicacao)}>
                  <button className="copy-button">Copiar</button>
                </CopyToClipboard>
                <p><strong>Publicação:</strong> {pub.publicacao}</p>
                <p><strong>Data de Disponibilização:</strong> {pub.data_disponibilizacao}</p>
                <p><strong>Data de Publicação:</strong> {pub.data_publicacao}</p>
                <p><strong>Jornal:</strong> {pub.jornal}</p>
                <p><strong>Local:</strong> {pub.local}</p>
                <p><strong>Página:</strong> {formatTextWithLineBreaks(pub.pagina)}</p>
              </div>
              <div className="action-selection">
               <InputLabel id="action-select-label">Selecione uma ação</InputLabel>
                <Select
                  value={selectedActions[`${process.process_number}-${pub.id}`] || ''}
                  onChange={(e) => handleActionChange(process.process_number, pub.id, e)}
                  labelid='action-select-label'
                  label="Selecione uma ação"
                >
                  {actions.map(action => (
                    <MenuItem key={action.value} value={action.value}>{action.label}</MenuItem>
                  ))}
                </Select>
                {selectedActions[`${process.process_number}-${pub.id}`] === '0' && (
                  <TextField
                    placeholder="Adicionar ação personalizada"
                    value={customActions[`${process.process_number}-${pub.id}`] || ''}
                    onChange={(e) => handleCustomActionChange(process.process_number, pub.id, e)}
                  />
                )}
              </div>
              {process.publications.indexOf(pub) === process.publications.length - 1 && (
                <div className="non-originals">
                  <strong>Publicações Duplicadas para esse processo:</strong>
                  {process.non_original_publications.join(', ')}
                </div>
              )}
            </div>
          </div>
        ))
      )}
      <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={() => handleSubmitAction()}>PRÓXIMO</Button>
      </Box>
    </div>
    
  );
};

export default ReadingPage;
