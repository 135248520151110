import React, { useState } from 'react';
import Dropzone from './Dropzone';
import { useNavigate } from 'react-router-dom';
import instance from '../../axiosInstance';
import './DailyReadings.css';
import { Typography, Button, TextField, Box, Grid, Alert } from '@mui/material';
import { REACT_ROUTES } from '../../routes';

const DailyReadings = () => {
  //const [fileContent, setFileContent] = useState('');
  const [manualText, setManualText] = useState('');
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (content) => {
    const lines = content.split('\n');
    const index = lines.findIndex(line => line.startsWith('Publicação:'));
  
    if (index === -1) {
      setErrorMessage('Erro: A linha "Publicação:" não foi encontrada. Este texto é do CONJUR?');
      return;
    }
  
    const remainingLines = lines.slice(index).join('\n');
  
    try {
      const response = await instance.post('/dailyreadings/', { 
        fileContent: remainingLines,
        title: title 
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log(response.data);
      navigate(REACT_ROUTES.DUPLICATES.replace(':dailyReadingId', response.data.daily_reading_id));
    } catch (error) {
      console.error('Error uploading content:', error);
      setErrorMessage('Erro ao processar arquivo.');
    }
  };
  
  
  const handleFileDrop = (content) => {
    //setFileContent(content);
    handleSubmit(content);
  };
  
  const handleManualTextSubmit = () => {
    handleSubmit(manualText);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {errorMessage && <Alert severity="error" gutterBottom>{errorMessage}</Alert>}
      <Typography variant="h4" gutterBottom>
        Leitura de Publicações
      </Typography>
      <TextField
        label="Título para ajudar a lembrar desta leitura (opcional)"
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mb: 2 }} // Add margin-bottom to separate from the next component
      />
      <Dropzone onFileDrop={handleFileDrop} />
      <Grid container spacing={2} sx={{ marginTop: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Ou Cole o texto manualmente</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleManualTextSubmit}
            fullWidth
          >
            PROCESSAR
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            value={manualText}
            onChange={(e) => setManualText(e.target.value)}
            placeholder="Cole o texto aqui..."
            rows={30}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleManualTextSubmit}
            fullWidth
          >
            PROCESSAR
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DailyReadings;
