import React from 'react';
import PublicationItem from './PublicationItem';

const ProcessItem = ({ process, handleCheckboxChange, compareTexts }) => {
    const getRowColor = (similarity) => {
        if (similarity < 0.7) {
          return 'red';
        } else if (similarity < 0.9) {
          return '#FFA500'; // orange
        } else {
          return 'green';
        }
      };

  return (
    <div className="process">
      <button className="accordion">
        {process.process_number} {process.publications.length} Publicações, núm.: {process.publications.map(pub => pub.publicacao).join(' | ')}
        <input
          type="checkbox"
          className="read-checkbox"
          checked={process.is_read}
          onChange={(e) => handleCheckboxChange('is_read', process.id, e.target.checked)}
        />
      </button>
      <div className="panel">
        {process.publications.length === 1 ? (
            <div className="panel-inner">
                <PublicationItem
                    key={process.publications[0].id}
                    publication={process.publications[0]}
                    handleCheckboxChange={handleCheckboxChange}
                    compareTexts={compareTexts}
                />
            </div>
        ) : (
          <div className="panel-inner">
            {process.publications.map((pub) => (
              <PublicationItem
                key={pub.id}
                publication={pub}
                handleCheckboxChange={handleCheckboxChange}
                compareTexts={compareTexts}
              />
            ))}
            <button className="subaccordion">Comparar</button>
            <div className="subpanel">
              <table>
                <thead>
                  <tr>
                    <th>Similaridade</th>
                    <th>PubA</th>
                    <th>PubB</th>
                    <th>Comparar</th>
                  </tr>
                </thead>
                <tbody>
                  {process.table.map((row, index) => (
                    <tr key={index} style={{ color: getRowColor(row.similarity) }}>
                        <td>{(row.similarity * 100).toFixed(2)}%</td>
                        <td>#{row.pub_a}</td>
                        <td>#{row.pub_b}</td>
                        <td>
                            <button onClick={() => compareTexts(row.pub_a, row.pub_b)}>
                            Comparar
                            </button>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessItem;
