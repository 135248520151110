import React from 'react';
import ProcessItem from './ProcessItem';

const ProcessList = ({ processData, handleCheckboxChange, compareTexts }) => {
  return (
    <div>
      {processData.map((process) => (
        
        <ProcessItem
          key={process.process_number}
          process={process}
          handleCheckboxChange={handleCheckboxChange}
          compareTexts={compareTexts}
        />
      
      ))}
    </div>
  );
};

export default ProcessList;
